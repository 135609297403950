body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: OpenSansRegular;
  src: url(/static/media/OpenSans-Regular.22ab03a6.ttf);
}

@font-face {
  font-family: OpenSansBold;
  src: url(/static/media/OpenSans-Bold.8ff9b573.ttf);
}

.App-error {
  display: flex;
  flex-direction: column;
}

.App-error-header {
  height: 20px;
  margin-top: 10vmin;
  font-style: normal;
  font-weight: 600;
  padding: 20px;
  text-align: center;
}

.App-error-title {
  font-size: 26px;
  font-family: OpenSansBold;
}

.App-error-subtitle {
  margin-top: 10px;
  font-size: 20px;
  font-family: OpenSansRegular;
}

.App-error-body {
  display: flex;
  justify-content: center;
}

.App-error-body-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  max-width: 450px;
  flex-wrap: wrap;
}

.App-error-body-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px;
  padding: 20px;
  cursor: pointer;
}

.App-error-body-item-img {
  width: 50px;
  height: 50px;
}

.App-error-body-item-title {
  margin-top: 3vmin;
  font-family: OpenSansRegular;
}

@media (min-width: 1069px) {
  .App {
    margin: 0;
    padding: 0;
    overflow-y: hidden;
  }
  
  .App-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 100vh;
    border-radius: 0px;
    float: left;
  }

  .App-form {
    height: 100vh;
    width: 40%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    font-size: calc(10px + 2vmin);
  }
}

@media (max-width: 1069px) {
  .App-banner {
    display: none;
  }

  .App {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
  }

  .App-form {
    height: 100vh;
    width: 100%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    font-size: calc(10px + 2vmin);
  }
}

.Banner-img {
  width: 50vmin;
  margin-top: -20vmin;
}

.Banner-title {
  position: absolute;
  height: 20px;
  margin-top: 40vmin;
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  padding-left: 20px;
  /* line-height: 20px; */
  /* identical to box height, or 53% */
  letter-spacing: 0.25px;
}

.Banner-subtitle {
  position: absolute;
  height: 20px;
  margin-top: 55vmin;
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  padding-left: 20px;
  /* line-height: 20px; */
  /* identical to box height, or 77% */
  letter-spacing: 0.25px;
}

.App-logo {
  /* height: 20vmin; */
  width: auto;
  height: 55px;
  margin-bottom: 45px;
  margin-top: 45px;
  pointer-events: none;
}

.App-logo--authenticator {
  height: 55px;
}

.label-box {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

label {
  height: 20px;
  /* Body 2 */
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.25px;
  /* Gray 3 */
  color: #828282;
}

.App-title {
  height: 20px;
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 20px;
  /* identical to box height, or 95% */
  letter-spacing: 0.25px;
  color: #16192C;
}

.App-subtitle {
  height: 20px;
  /* Body 2 */
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.25px;
  color: #545D69;
}

.App-input {
  background: transparent;
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 13px;
  grid-gap: 13px;
  gap: 13px;
  width: 330px;
  height: 51px;
  border: 1px solid #DED2D9;
  border-radius: 5px;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input {
  width: 330px;
  margin-bottom: 10px;
  height: 32px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  background: #101011;
  color: #000;
  border-color: rgb(203, 254, 252);
}

button {
  margin-top: 32px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  /* padding: 5px; */
  width: 330px;
  height: 45px;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  margin-bottom: 20px;
  transition: .3s;
}

button:hover {
  opacity: 0.8;
}

.error-gif {
  width: 300px;
}

.error-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  color: #000;
  background: #61dafb;
  border: 1px solid;
  border-color: rgb(203, 254, 252);
  width: 300px;
  height: 32px;
  padding: 3px 10px;
}

.error-link:hover {
  background: #61dafbdb;
  cursor: pointer;
}

#rc-anchor-container {
  width: 250px;
}

.mode-link-wrap {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 25px;
}

.mode-link-title {
  /* Not Registered Yet? */
  height: 20px;
  /* Body 2 */
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.25px;
  color: #545D69;
}

.mode-link {
  height: 20px;
  /* Body 2 */
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.25px;
}

.mode-link:hover {
  opacity: 0.8;
  cursor: pointer;
}

.separator-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.separator-line-before {
  height: 0.5px;
  width: 125px;
  margin-top: 5px;
  background: #c2c8d0;
  margin-right: 10px;
}

.separator-line-text {
  height: 20px;
  font-family: OpenSansRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #545D69;
}

.separator-line-after {
  height: 0.5px;
  margin-top: 5px;
  margin-left: 10px;
  width: 125px;
  background: #c2c8d0;
}

.App-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 10000;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.App-modal-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
}

.App-modal-text {
  background: #fff;
  z-index: 1;
  font-family: OpenSansRegular;
  padding: 15px 20px 0px 20px;
  border-radius: 3px;
}

.App-modal-text-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.App-modal-text-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 20px;
}

.App-modal-input {
  background: transparent;
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 13px;
  grid-gap: 13px;
  gap: 13px;
  width: 300px;
  height: 51px;
  border: 1px solid #DED2D9;
  border-radius: 5px;
}

.App-modal-text-btn {
  width: 100px;
}

.App-modal-text-btn-cancel {
  background: #c2c8d0;
  transition: .3s;
}

.App-modal-text-btn-cancel:hover {
  opacity: 0.8;
  box-shadow: 0 11px 9px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 29%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.App-modal-text-btn-send {
  background: #000;
  color: white;
  transition: .3s;
}

.App-modal-text-btn-send:hover {
  opacity: 0.8;
  box-shadow: 0 11px 9px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 29%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

